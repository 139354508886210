/* Page Parcours
========================================================================================= */

 #page-parcours {
 	position: relative;
 	padding-left: 4rem;
 	padding-right: 4rem;

	&:after {
		content: "";
		width: 2rem;
		height: 100%;
		position: absolute;
		background-color: $color3;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: -1;
	}

	h3 {
		padding-top: 10rem;
	}
	
 	#experience-wrapper {
 		overflow: hidden;

 		.row {
 			margin: 0;
 			
		 	.experience {
		 		width: 50%;

		 		&.experience-pro {
		 			padding-right: 1rem;

		 			.experience-item {
		 				padding-left: 16vw;
		 				padding-right: 15rem;

		 				&:before {
			 				content: "";
			 				width: 10rem;
			 				height: 1rem;
			 				background: $color3;
			 				position: absolute;
			 				right: 0;
			 				top: 50%;
			 				transform: translateY(-50%);
			 			}
			 			
			 			&:after {
			 				content: "";
			 				width: 2px;
			 				height: 10rem;
			 				background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, $color3 50%,rgba(255,255,255,0) 100%);
			 				position: absolute;
			 				right: 10rem;
			 				top: 50%;
			 				transform: translateY(-50%);
			 			}
		 			}
		 		}

		 		&.formation {
		 			padding-left: 1rem;
		 			float: right;

		 			.experience-item {
		 				padding-right: 10vw;
		 				padding-left: 15rem;

		 				&:before {
			 				content: "";
			 				width: 10rem;
			 				height: 1rem;
			 				background: $color3;
			 				position: absolute;
			 				left: 0;
			 				top: 50%;
			 				transform: translateY(-50%);
			 			}
			 			
			 			&:after {
			 				content: "";
			 				width: 2px;
			 				height: 10rem;
			 				background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, $color3 50%,rgba(255,255,255,0) 100%);
			 				position: absolute;
			 				left: 10rem;
			 				top: 50%;
			 				transform: translateY(-50%);
			 			}
		 			}
		 		}

		 		.experience-item {
		 			margin-bottom: 5rem;
		 			position: relative;

		 			p {
		 				margin: 0;
		 				font-weight: 300;

		 				&:first-of-type {
							margin-bottom: 1rem;
		 				}

		 				&:last-of-type {
							text-transform: inherit;
							margin-top: 1rem;
		 				}
		 			}
		 		}
		 	}
 		}
 	}

 	#parcours-cv {
 		padding: 2rem 0rem 10rem;
 		position: relative;

 		&:after {
 			content: "";
 			position: absolute;
 			width: 50%;
 			bottom: 0;
 			left: 0;
 			background: $color3;
 			height: 2rem;
 		}
 	}
 }