/* Page Contact
========================================================================================= */

#contact-map {
	width: 100%;
	height: 40rem;
}
#page-contact {
	padding: 0 15rem 8rem;
	position: relative;

	&:before {
		content: "";
		width: 2rem;
		height: 100%;
		background: $color3;
		position: absolute;
		left: 4rem;
		top: 0;
		z-index: -1;
	}

	#contact-form {

		form {

			.row {
				margin-bottom: 0;
			}

			p {
				margin-bottom: 1.5rem;

				input, textarea {
					margin-bottom: 0;
				}

				span.wpcf7-not-valid-tip {
					font-size: 1.2rem;
					font-weight: 300;
				}
			}

			> p:last-of-type {
				margin-bottom: 0;
			}

			.wpcf7-response-output {
				margin: 0 auto;
				border-width: 1px;
				font-size: 1.2rem;
				background-color: $color0;
				padding: 0.5rem 1rem;

				&.wpcf7-mail-sent-ok {
					border-color: $color3;
				}
			}
		}
	}

	p {
		margin: 0;
	}


	#contact-info-wrapper {
		padding-left: 3.75rem;

		#contact-info-nom {
			font-size: 4rem;
			margin: 0;
			line-height: 0.8;
			margin-bottom: .5rem;
			margin-left: -.2rem;
		}

		#contact-info-description {
			color: $color1;
			font-size: 1.2rem;
			margin: 0;
		}

		#contact-info-coordonnees {
		margin: 3rem 0; 

		> * {
			margin: 1rem 0;
		}

		a {
			color: $color2;
			text-transform: inherit;
		}
		}

		#contact-info-rs {
			overflow: hidden;

			a {
				height: 2.5rem;
				width: 2.5rem;
				margin-right: 1rem;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				float: left;
			}
		}
	}
}