/* Fonts
========================================================================================= */

@font-face {
  font-family: 'icomoon';
  src:  url('../font/icomoon.eot?2dohe8');
  src:  url('../font/icomoon.eot?2dohe8#iefix') format('embedded-opentype'),
    url('../font/icomoon.ttf?2dohe8') format('truetype'),
    url('../font/icomoon.woff?2dohe8') format('woff'),
    url('../font/icomoon.svg?2dohe8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-all:before {
  content: "\f069";
}
.icon-circle:before {
  content: "\f111";
}
.icon-web:before {
  content: "\f121";
}
.icon-print:before {
  content: "\e908";
}
.icon-audiovisuel:before {
  content: "\e90f";
}
.icon-calendar:before {
  content: "\e953";
}
.icon-webdesign:before {
  content: "\e956";
}
.icon-gestion:before {
  content: "\e99c";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-fierte:before {
  content: "\e9d7";
}
.icon-sort-alpha-asc:before {
  content: "\ea48";
}
.icon-google:before {
  content: "\ea8b";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-linkedin:before {
  content: "\eaca";
}
.icon-menu:before {
  content: "\e9bd";
}
