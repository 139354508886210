/* 404
========================================================================================= */

.error-404 {

	header#page-header {
		display: none;
	}

	#page-main {
		display: flex;
		justify-content: center;
		align-items: center;
		height: calc(100vh - 5.76rem);

		#error-404-content {
			h3 {
				padding: 0;
			}
			h4 {
				font-size: 2rem;
				color: $color1;
				letter-spacing: .25rem;
				font-weight: 300;
				margin-top: 0;
				margin-bottom: 3rem;
			}
		}
	}

	footer#page-footer {
		&:before {
			display: none;
		}
	}
}
