/* Responsive
========================================================================================= */

@media #{$extra-large-and-up} {
	#page-home {

		#home-situation {

			p{
				font-size: 2rem;
			}
		}
	}
}


@media #{$large-and-up} {

	header#page-header {

		nav#page-nav {
			background: linear-gradient(rgba(36,36,36,0.9) 80%, rgba(255,255,255,0) 100%);
		
		}
	}

	#page-contact {

		#contact-form {

			form {

				.wpcf7-response-output {
					margin-top: 1.5rem;
				}
			}
		}
	}
}

@media #{$medium-and-up} {

}

@media screen and (min-width: $medium-screen-up) and (max-width: $large-screen) {

	h3 {
		font-size: 3rem;
		
	}

	#page-presentation {
		
		p {
			font-size: 3rem;
		}
	}

	#page-parcours {

		#experience-wrapper {

			.row {
				
		 	.experience {

		 		&.experience-pro {

		 			.experience-item {
		 				padding-left: 8vw;
		 				padding-right: 15rem;
		 			}
		 		}

		 		&.formation {

		 			.experience-item {
		 				padding-right: 6vw;
		 				padding-left: 15rem;
		 			}
		 		}

		 		.experience-item {
		 			margin-bottom: 5rem;
		 			position: relative;

		 			p {
		 				margin: 0;
		 				font-weight: 300;

		 				&:first-of-type {
							margin-bottom: 1rem;
		 				}

		 				&:last-of-type {
							text-transform: inherit;
							margin-top: 1rem;
		 				}
		 			}

		 			&.experience-pro {
		 			}

		 			&.formation {

		 			}
		 		}
		 	}
			}
		}

		#parcours-cv {
			padding: 2rem 0rem 10rem;
			position: relative;

			&:after {
				content: "";
				position: absolute;
				width: 50%;
				bottom: 0;
				left: 0;
				background: $color3;
				height: 2rem;
			}
		}
	}
}

@media #{$medium-only} {

	.projet-item {

		&:before {
			content: "";
			width: 100%;
			height: 100%;
			background-color: $color0;
			position: absolute;
		}
	}

}

@media #{$medium-and-down} {

	h3 {
		font-size: 3rem;
	}

	 #page-presentation {
 	
		p {
			font-size: 2rem;
		}
	}
	
	header#page-header {
		
		nav#page-nav {
			width: auto;
			padding: 1rem;

			#mobile-demo {
				background: $color0;
				padding: 1rem 0;
				border-right: solid 2rem $color3;

				li {

					a {
						height: auto;
						font-weight: 300;
						font-size: 1.6rem;
						padding: 1rem 3.2rem;
						color: $color2;
					}
				}
			}
		}
	}

	#page-parcours {

		#experience-wrapper {

			.row {
				margin-bottom: 4rem;
				
			 	.experience {
			 		width: 66.66%;
			 		background-color: $color0;
			 		padding: 2rem;
				 	border: 1px solid $color1;

			 		&.experience-pro {
			 			padding-right: 2rem;

			 			.experience-item {
			 				padding-left: 0;
			 				padding-right: 0;

			 				&:before {
				 				display: none;
				 			}
				 			
				 			&:after {
				 				display: none;
				 			}
			 			}
			 		}

			 		&.formation {
			 			padding-left: 2rem;

			 			.experience-item {
			 				padding-right: 0;
			 				padding-left: 0;

			 				&:before {
				 				display: none;
				 			}
				 			
				 			&:after {
				 				display: none;
				 			}
			 			}
			 		}

			 		.experience-item {
			 			margin: 0;
			 		}
			 	}
			}
		}

		#parcours-cv {
			padding: 2rem 0rem 10rem;
			position: relative;

			&:after {
				content: "";
				position: absolute;
				width: 50%;
				bottom: 0;
				left: 0;
				background: $color3;
				height: 2rem;
			}
		}
	}

	 #page-portfolio {
		padding: 0 4rem;

		.section-title {

			&:before {
				height:100%;
				left: 0;
			}
		}

		.section-content {
			padding: 0;

		 	&:before {
		 		content: "";
		 		width: 2rem;
		 		height: 14rem;
		 		bottom: 1rem;
		 		right: .75rem;
		 		position: absolute;
		 		background-color: $color3;
		 		z-index: -1;
		 	}

		 	&:after {
		 		content: "";
		 		width: calc(50% - 1.25rem);
		 		height: 2rem;
		 		bottom: 14rem;
		 		right: .75rem;
		 		position: absolute;
		 		background-color: $color3;
		 		z-index: -1;
		 	}

			#projet-isotope {

				.projet-isotope-header-wrapper {
		 			position: relative;
		 			margin: 1rem 0;

					&:before {
						display: none;
					}

			 		h5 {
			 			height: auto;
			 			text-align: left;
			 			width: 100%;
			 		}
				}

				#projet-isotope-content {
					margin: 0 -.75rem;

				 	#projet-filtre, #projet-tri {
						padding: 0 .75rem;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;

				 		.btn {
				 			width: calc(33.33% - 0.5rem);
				 			padding: 1rem 1.5rem;
				 			margin-bottom: 1rem;

				 			i {
				 				position: inherit;
				 				left: inherit;
								top: .2rem;
								margin-right: 1rem;
								transform: inherit;
				 			}
				 		}
				 	}
				}
			}
		 	
		 	#projet-wrapper {
				
				#projet-wrapper-row {
					margin-right: -.5rem;
					margin-left: -.5rem;

			 		.projet-item {
			 			width: 50%;
			 			height: 24rem;

			 			&:last-of-type {

			 				&:after {
			 					content: "";
			 					position: absolute;
			 					left: 0.5rem;
			 					width: 100rem;
			 					height: 2rem;
			 					background: $color3;
			 					top: 50%;
			 					transform: translateY(-50%);
			 				}
			 			}

			 			.projet-item-wrapper {

				 			.projet-bg {
		 						opacity: 0.1;
		 						filter: grayscale(75%);
		 					}

				 			.projet-content {
				 				opacity: 1;
		 						border: solid 1px $color1;

				 				.projet-fierte, .projet-lien{
				 					font-size: 1.6rem;
				 				}
				 			}
			 			}
			 		}
				}


		 		&:before {
		 			display: none;
		 		}

		 		&:after {
		 			left: .75rem;
		 			height: 3rem;
		 			top: 0rem;
		 		}
		 	}
		}
	}

	#portfolio-contact-link {
		width: calc(100% - 8rem);
		height: 2rem;
		background-color: $color3;
		margin: 0 auto;
		margin: 7rem auto 8rem auto;
		position: relative;

		&:before {
			content: "";
		top: -8rem;
		height: 8rem;
		width: 2rem;
		background-color: $color3;
		position: absolute;
		right: 0;
		}

		&:after {
		content: "";
		bottom: -8rem;
		height: 8rem;
		width: 2rem;
		background-color: $color3;
		position: absolute;
		left: 0;
		}
	}

	#page-contact {
		padding: 0 15rem 8rem;
		position: relative;

		.btn {
			width: 100%;
		}

		#contact-info-wrapper {
			margin-top: 6rem;
			padding-left: .75rem;

			#contact-info-rs {

				a {
					height: 4rem;
					width: 4rem;

					i {
						font-size: 2rem;
					}
				}
			}
		}
	}
}

@media #{$small-and-down} {

	.btn-large {
		width: 100%;
	}

	header#page-header {
		
		nav#page-nav {

			#mobile-demo {
				border-width: 1rem;
			}
		}
	}
	

	#page-home {

		h2 {
			font-size: 3vw;
			letter-spacing: 0.05rem;
		}

		#home-situation {
			padding: 0 5em;

			p{
				font-size: 3vw;
			}
		}

		.home-gradient {

	 		&:before {
	 			left: -1rem;
	 			width: 1rem;
	 		}
			
			bottom: 2rem;
	 		width: calc(100% - 5rem);
	 		height: 1rem;
	 		transform: translateX(calc(-50% + .5rem));

	 		&:after {
	 			width: 1rem;
	 			bottom: -2rem;
	 			height: 2rem;
	 		}
	 	}
	}

	#page-presentation {
		padding: 4rem 5rem 5rem;

		&:before {
			right: 2rem;
			width: 1rem;
		}

		&:after {
			width: calc(50% - 2rem);
			height: 1rem;
			right: 2rem;
			bottom: -1rem;
		}
	}

	#page-parcours {
		padding: 0 2rem;

		&:after {
			width: 1rem;
		}

		h3 {
			font-size: 5vw;
			padding: 6rem 0 3rem;
		}

		#experience-wrapper {

			.row {
				margin-bottom: 3rem;
				
			 	.experience {
			 		width: 80%;
			 		padding: 1rem;

			 		&.experience-pro {
			 			padding-right: 1.5rem;
			 		}

			 		&.formation {
			 			padding-left: 1.5rem;
			 		}
			 	}
			}
		}
	
		#parcours-cv {

			&:after {
				height: 1rem;
			}
		}
	}

	#page-portfolio {
		padding: 0 4rem;

		.section-title {

			&:before {
				height:100%;
				left: 0;
			}
		}

		.section-content {
			padding: 0;

		 	&:before {
		 		display: none;
		 	}

		 	&:after {
		 		display: none;
		 	}

			#projet-isotope {

				.projet-isotope-header-wrapper {
		 			position: relative;
		 			margin: 1rem 0;

					&:before {
						display: none;
					}

			 		h5 {
			 			height: auto;
			 			text-align: left;
			 			width: 100%;
			 		}
				}

				#projet-isotope-content {
					margin: 0 -.75rem;

				 	#projet-filtre, #projet-tri {
						padding: 0 .75rem;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;

				 		.btn {
				 			width: calc(33.33% - 0.5rem);
				 			padding: 1rem .5rem;
				 			margin-bottom: 1rem;

				 			i {
				 				position: inherit;
				 				left: inherit;
								top: .2rem;
								margin-right: 1rem;
								transform: inherit;
				 			}
				 		}
				 	}
				}
			}
		 	
		 	#projet-wrapper {

		 		#projet-wrapper-row {
		 			margin-bottom: 0;

			 		.projet-item {
			 			width: 100%;
			 			padding: 0 .5rem;

			 			.projet-item-wrapper {

				 			.projet-bg {
		 						opacity: 0.1;
		 						filter: grayscale(75%);
		 					}

				 			.projet-content {
				 				opacity: 1;
		 						border: solid 1px $color1;

				 				.projet-fierte, .projet-lien{
				 					font-size: 1.6rem;
				 				}
				 			}
			 			}
			 		}
		 		}
		 		

		 		&:before {
		 			display: none;
		 		}

		 		&:after {
		 			left: 0;
		 			height: 4rem;
		 		}
		 	}
		}
	}

	 #page-portfolio {
		padding: 0 2rem;

		.section-title {

			&:before {
				width: 1rem;
			}
		}

		.section-content {

		 	&:before {
		 		display: none;
		 	}

		 	&:after {
		 		display: none;
		 	}

			#projet-isotope {

				#projet-isotope-content {

				 	#projet-filtre, #projet-tri {

				 		.btn {
				 			width: calc(50% - 0.5rem);
				 		}
				 	}
				}
			}
		 	
		 	#projet-wrapper {
		 		
		 		.projet-item {
		 			width: 100%;
		 			padding: 0;

		 			&:last-of-type {

		 				&:after {
		 					content: "";
		 					position: absolute;
		 					left: 0.5rem;
		 					width: 100rem;
		 					height: 2rem;
		 					background: $color3;
		 					top: 50%;
		 					transform: translateY(-50%);
		 				}
		 			}

		 			.projet-item-wrapper {

			 			.projet-bg {
	 						opacity: 0.1;
	 						filter: grayscale(75%);
	 					}

			 			.projet-content {
			 				opacity: 1;
	 						border: solid 1px $color1;

			 				.projet-fierte, .projet-lien{
			 					font-size: 1.6rem;
			 				}
			 			}
		 			}
		 		}

		 		&:before {
		 			display: none;
		 		}

		 		&:after {
		 			left: .75rem;
		 			width: 1rem;
		 		}
		 	}
		}
	}

	 #portfolio-contact-link {
	 	height: 1rem;
	 	margin: 7rem auto 8rem auto;
	 	width: calc(100% - 6rem);

	 	&:before {
			top: -8rem;
			height: 8rem;
			width: 1rem;
	 	}

	 	&:after {
			bottom: -8rem;
			height: 8rem;
			width: 1rem;
	 	}
	 }

	#contact-map {
		height: 30rem;
	}

	#page-contact {
		padding: 0 2rem 6rem;

		&:before {
			width: 1rem;
			left: 3rem;
		}

		#contact-form {

			form {

				p {

					span.wpcf7-not-valid-tip {
						padding-left: 3rem;
					}
				}
			}
		}

		#contact-info-wrapper {
			padding-left: 4rem;

	 		#contact-info-nom {
	 			font-size: 2.8rem;
	 		}
		}
	}

	footer#page-footer {
		padding: 2rem 2rem;

		&:before {
			left: 3rem;
			width: 1rem;
		}
	}
}