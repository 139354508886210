/* Header 
========================================================================================= */

header#page-header {
	float: left;

	nav#page-nav {
		background: none;
		height: inherit;
		line-height: inherit;
		box-shadow: none;
		padding: 1.25rem 1.5rem 2rem;
		height: auto;

		.nav-wrapper {
			height: auto;

			#nav-menu-top {
				overflow: hidden;

				#menu-top {
					width: 100%;
					display: flex;
					justify-content: space-between;

					li {

						&:first-child {

							a.menu-active {
								color: $color1;
							}
						}

						a {
							font-size: 1.3rem;
							color: $color1;
							font-weight: 200;
							text-transform: uppercase;
							padding: 0;

							&:hover, &.menu-active {
								background: none;
								color: $color2;
							}
						}
					}
				}
			}
		}
	}
}

#sidenav-overlay {
	z-index: 996;
}
