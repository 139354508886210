/* Page Home
========================================================================================= */

 #page-home {
 	min-height: 100vh;
 	position: relative;
 	padding-bottom: 6rem;

 	h1 {
 		margin: 0;
		font-size: 7vw;
		transform: scale(0);

 		span {
 			margin-left: -2vw;
 		}
 	}

 	h2 {
 		font-size: 2vw;
 		color: $color1;
 		margin: 0;
 		transform: scale(0);
 	}

 	#home-situation {
 		width: 100%;
 		padding: 0 8.5rem;
 		text-align: center;
 		position: absolute;
 		bottom: 12rem;
 		transform: scale(0);

 		p {
 			// box-shadow: $box-shadow;
	 		color: $color1;
	 		text-transform: uppercase;
	 		font-size: 1.75vw;
	 		border: 1px solid $color1;
	 		text-align: center;
	 		margin: 0 auto;
	 		width: auto;
	 		display: inline-block;
	 		padding: 1rem 2rem;
 		}
 	}

 	.home-gradient {

 		&:before {
 			content: "";
 			position: absolute;
 			left: -2rem;
 			bottom: 0;
 			width: 2rem;
 			height: 50vh;
 			background:linear-gradient(to bottom, rgba(0,226,229,0) 0%,rgba(0,226,229,0.25) 100%);
 		}

 		width: calc(100% - 10rem);
 		height: 2rem;
 		background: linear-gradient(to right, rgba(0,226,229,0.25) 0%,rgba(0,226,229,.95) 100%);
 		position: absolute;
 		left: 50%;
 		transform: translateX(calc(-50% + 1rem));
 		bottom: 4rem;

 		&:after {
 			content: "";
 			position: absolute;
 			right: 0;
 			bottom: -4rem;
 			width: 2rem;
 			height: 4rem;
 			background:linear-gradient(to bottom, rgba(0,226,229,0.95) 0%,rgba(0,226,229,1) 100%);
 		}
 	}
 }