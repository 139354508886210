/* Mentions légales
========================================================================================= */

#mentions-legales {
	background-color: $color0;
	border-radius: 0;
	
	h5 {
		font-size: 3rem;
		margin-top: 0;
		text-transform: uppercase;
		text-align: center;
		font-weight: 200;
	}

	h6 {
		font-size: 1.6rem;
		font-weight: 200;
		margin-top: 3rem;
	}

	*
	p, a{
		text-transform: inherit;

		strong {
			font-weight: 300;
		}
	}
	p:last-of-type {
		margin-bottom: 0;
	}
}
