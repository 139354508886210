/* Page Philosophie
========================================================================================= */

 #page-presentation {
 	padding: 6rem 10rem 10rem;
 	position: relative;

 	&:before {
 		content: "";
 		position: absolute;
 		right: 4rem;
 		top: 0;
 		height: 100%;
 		width: 2rem;
 		background-color: $color3;
 	}

 	&:after {
 		content: "";
 		position: absolute;
 		width: calc(50% - 4rem);
 		height: 2rem;
 		right: 4rem;
 		bottom: -2rem;
 		background: $color3;
 	}

 	#presentation-tag-wrapper {
 		text-align: center;
 		margin-bottom: 4rem;

 		.presentation-tag {
 			padding: 0.5rem 1rem;
 			border: 1px solid $color1;
 			color: $color1;
 			display: inline-block;
 			margin: .4rem .25rem;

 			a {
 				color: inherit;
 			}
 		}
 	}
 	
 	p {
 		font-size: 5rem;
 		margin: 0;
 	}

 	#home-philosophie-auteur {
 		margin: 0 auto;
 		display: block;
 		text-align: center;
 		color: $color1;
 	}
 }