/* Page Projet
========================================================================================= */

 #page-portfolio {

 	.section-title {
		position: relative;

 		&:before {
 			content: "";
 			position: absolute;
 			left: 2.5rem;
 			height: calc(100% + 2rem);
 			width: 2rem;
 			background: $color3;
 		}
 	}
 	.section-content {
 		padding: 0 9.5rem 0 10rem;
 		position: relative;
 		overflow: hidden;

	 	&:before {
	 		content: "";
	 		width: 2rem;
	 		height: calc(9vw + 2rem);
	 		bottom: 1rem;
	 		right: 3.25rem;
	 		position: absolute;
	 		background-color: $color3;
	 	}

	 	&:after {
	 		content: "";
	 		width: 6.25rem;
	 		height: 2rem;
	 		bottom: calc(9vw + 2rem);
	 		right: 3.25rem;
	 		position: absolute;
	 		background-color: $color3;
	 	}

		#projet-isotope {

			.projet-isotope-header-wrapper {
	 			position: relative;

				&:before {
					content: "";
					position: absolute;
					width: calc(100% + 8.5rem);
					height: 2rem;
					left: -7.5rem;
					top: 50%;
					transform: translateY(-50%);
					background-color: $color3;
				}

		 		h5 {
					position: relative;
		 			font-weight: 300;
		 			font-size: 1.2rem;
		 			margin: 0;
		 			background-color: $color0;
		 			width: 50%;
		 			height: 4rem;
		 		}
			}

			#projet-isotope-content {
				margin-left: -.5rem;
				margin-right: -.5rem;

			 	#projet-filtre, #projet-tri {
					padding: 0 .5rem;

			 		.btn {
			 			width: 100%;
			 			padding: 0.5rem 0.75rem 0.5rem 2.5rem;
			 			margin-bottom: 1rem;
			 			position: relative;

			 			i {
			 				position: absolute;
			 				left: 1rem;
							top: 50%;
							transform: translateY(-50%);
			 			}
			 		}
			 	}
			}
		}
	 	
	 	#projet-wrapper {
	 		padding-top: 4rem;
	 		position: relative;
	 		overflow: hidden;

	 		.projet-item {
	 			float: left;
	 			width: 50%;
	 			padding: 0 .5rem;
	 			height: 18vw;
	 			min-height: 23rem;
	 			margin-bottom: 1rem;

	 			&:last-of-type {

	 				.projet-item-wrapper {
						background-color: $color3;

			 			&:hover {

		 					.projet-bg {
		 						opacity: 1;
		 						filter: none;
		 					}

		 					.projet-content {
		 						opacity: 1; 						
		 						border: 0;
		 					}
			 			}

		 			}

	 				&:after {
	 					content: "";
	 					position: absolute;
	 					left: 0.5rem;
	 					width: 100rem;
	 					height: 2rem;
	 					background: $color3;
	 					top: 50%;
	 					transform: translateY(-50%);
	 				}
	 			}

	 			.projet-item-wrapper {
	 				position: relative;
	 				width: 100%;
	 				height: 100%;
		 			
		 			&:hover {

	 					.projet-bg {
	 						opacity: 0.1;
	 						filter: grayscale(100%);
	 					}

	 					.projet-content {
	 						opacity: 1;
	 						border: solid 1px $color3;
	 					}
		 			}

		 			.projet-bg {
		 				height: 100%;
		 				width: 100%;
		 				background-size: cover;
		 				background-position: 50% 50%;
	 					transition: $transition;
		 			}

		 			.projet-content {
		 				position: absolute;
		 				top: 0;
		 				width: 100%;
		 				height: 100%;
		 				padding: .5rem;
		 				opacity: 0;
	 					transition: $transition;

		 				.projet-content-info {
		 					height: 100%;

		 					h4 {
		 						font-size: 1.6rem;
		 						font-weight: 300;
		 						margin: 0;
		 					}

		 					hr {
								border: none;
		 						margin: 1rem 0;
								background-color: $color3;
								width: 12.5%;
								height: 1px;
		 					}

		 					ul {
		 						margin: 0;

		 						li {
		 							display: inline-block;
		 							margin: 0 0.5rem;
		 							font-size: 1.3rem;
		 						}
		 					}
		 				}

		 				.projet-tag {
		 					position: absolute;
		 					bottom: .5rem;
		 					margin: 0;
		 					overflow: hidden;

		 					.projet-tag-item {
		 						background-color: $color1;
		 						color: $color0;
		 						padding: 0.25rem 0.5rem;
		 						font-weight: 400;
		 						font-size: 1rem;
		 						text-transform: inherit;
		 						float: left;
		 						margin-right: .5rem;
		 						margin-top: .5rem;
		 						position: relative;
		 					}
		 				}

		 				.projet-fierte {
							position: absolute;
							top: 1rem;
		 					right: 1rem;
		 					font-size: 1.6rem;
		 				}

		 				.projet-lien {
							position: absolute;
							top: 1rem;
		 					left: 1rem;
		 					font-size: 1.6rem;
		 				}

		 				.projet-cours {
							position: absolute;
							top: 1.25rem;
		 					left: 1rem;
		 					font-size: 1rem;
		 					color: $color2;
		 					opacity: 0.25;
		 				}
		 			}
	 			}
	 		}

	 		&:before {
	 			content: "";
	 			position: absolute;
	 			height: 2rem;
	 			width: calc(50% / 2 - 1rem);
	 			top: 1rem;
	 			left: 0;
	 			background-color: $color3;
	 		}

	 		&:after {
	 			content: "";
	 			position: absolute;
	 			height: 3rem;
	 			width: 2rem;
	 			top: 1rem;
	 			left: calc(50% / 2 - 1rem);
	 			background-color: $color3;
	 		}
	 	}
 	}
 }

 #portfolio-contact-link {
 	width: calc(100% - 8rem);
 	height: 2rem;
 	background-color: $color3;
 	margin: 0 auto;
 	margin: 7rem auto 8rem auto;
 	position: relative;

 	&:before {
 		content: "";
		top: -8rem;
		height: 8rem;
		width: 2rem;
		background-color: $color3;
		position: absolute;
		right: 0;
 	}

 	&:after {
		content: "";
		bottom: -8rem;
		height: 8rem;
		width: 2rem;
		background-color: $color3;
		position: absolute;
		left: 0;
 	}
 }