/* Global
========================================================================================= */

html {
	font-size: 62.5%;
}

 body {
 	background-color: $color0;
 	color: $color2;
 	font-family: Montserrat, sans-serif;
	font-weight: 200;
 }

 #wrapper {
 	overflow: hidden;
 }

 p, a, span:not(.color3) {
 	font-size: 1.2rem;
 	text-transform: uppercase;
 }

 a {
 	color: $color3;
 }

 section {
 	padding: 0 1.5rem;
 }

 .pace-progress {
	background-color: $color3 !important;
}

::-moz-selection {
	color: $color0; 
	background: $color3;
}

::selection {
	color: $color0; 
	background: $color3;
} 

h1, h2, h3, h4, h5, h6 {
	text-transform: uppercase;
}

h1 {
	font-size: 10rem;
	font-weight: 200;
}

h2 {
	font-size: 3rem;
	letter-spacing: 0.25rem;
}

h3 {
	font-size: 5rem;
	font-weight: 200;
	padding-top: 8rem;
	padding-bottom: 8rem;
	margin: 0;
}

.btn,
.cc-compliance .cc-btn {
	font-size: 1.2rem;
	letter-spacing: inherit;
	background: $color0;
	border-radius: 0;
	height: inherit;
	width: auto;
	line-height: inherit;
	box-shadow: none;
	font-weight: 300;
	transition: $transition;
	border-width: 1px;

	&:hover {
		box-shadow: $box-shadow;	
	} 

	&.btn-large {
		padding: 1.5rem 2rem;
		min-width: 20rem;
	}

	&.btn-color3 {
		border: solid 1px $color3;

		&:hover {
			background-color: $color3;
		}
	}

	&.btn-color1 {
		border: solid 1px $color1;
		color: $color1;

		&:hover {
			border-color: $color2;
			background: none;
			color: $color2;
		}

		&:focus {
			background: none;
			border-color: $color3;
			box-shadow: $box-shadow;
			color: $color2;
		}
	}

	&.cc-dismiss {

		&:hover {
			background: $color2;
		}
	}

	&.btn-active, &.btn-active:hover {
		background: none;
		border-color: $color3;
		box-shadow: $box-shadow;
		color: $color2;
	}
}

input:not([type]), 
input[type=text], 
input[type=password], 
input[type=email], 
input[type=url], 
input[type=time], 
input[type=date], 
input[type=datetime], 
input[type=datetime-local], 
input[type=tel], 
input[type=number], 
input[type=search],
textarea,
textarea.materialize-textarea {
	border: solid 1px $color1;
	padding: 2rem 1.5rem;
	margin-bottom: 1.5rem;
	box-sizing: border-box;
	font-weight: 300;
	transition: $transition;
	font-size: 1.3rem;
	background: $color0;

	@include placeholder {
		text-transform: uppercase;
		color: $color1;
	}

	&:active, &:focus, &:focus:not([readonly]) {
		border: solid 1px $color3;
		// background-color: $color1;
		box-shadow: $box-shadow;
		outline: none;
	}
}

textarea {
	padding: 1.5rem;
	resize: none;
	min-height: 20rem;
	margin-bottom: 1rem;
}

.row {
	margin-left: -.75rem;
	margin-right: -.75rem;
}

.color0 {
	color: $color0;
}

.color1 {
	color: $color1;
}

.color2 {
	color: $color2;
}

.color3 {
	color: $color3;
}

.font-thin {
	font-weight: 200;
}

.font-light {
	font-weight: 300;
}

.font-bold {
	font-weight: 700;
}

.font-black {
	font-weight: 800;
}

.flex {
	display: flex;
}

.flex-align-start {
	align-items: flex-start;
}

.flex-align-center {
	align-items: center;
}

.flex-align-end {
	align-items: flex-end;
}

.flex-justify-center {
	justify-content: center;
}

.flex-justify-end {
	justify-content: flex-end;
}

.flex-column {
	flex-direction: column;
}

.flex-wrap {
	flex-wrap: wrap;
}

.cc-window.cc-banner {
	padding: 1rem 2rem !important;
	
	.cc-message {
		margin-bottom: 0;
		font-weight: 300;
	}
}
