/* Footer
========================================================================================= */

footer#page-footer {
	padding: 2rem 4rem;
	position: relative;

	&:before {
		content: "";
		position: absolute;
		left: 4rem;
		top: 0;
		width: 2rem;
		height: 100%;
		background-color: $color3;
		z-index: -1;
	}

	* {
		text-transform: inherit;
		margin: 0;
	}
}
